<template>
  <div class="complaint">
    <div class="theme">
      姜堰区娄庄镇人大常委会关于进一步加强改进人大工作意见征集表
    </div>
    <load-list :interFace="interFace">
      <template v-slot:list="props">
        <div class="content" v-for="(item, index) in props.list" :key="index">
          <p class="title">{{ index + 1 }}.{{ item.question }}</p>
          <div class="item-content">
              {{item.answer}}
          </div>
        </div> </template
    ></load-list>
  </div>
</template>
      </load-list>
    <van-button class="btn" @click="submit">提交</van-button>
  </div>
</template>
<script>
import LoadList from "@/components/SearchLoadList";
import { getCollectDetail } from "../api";
export default {
  name: "myProposalDetail",
  components: {
    LoadList,
  },
  data() {
    return {
      interFace: {
        api: getCollectDetail,
        params: {
          id: this.$route.params.id,
        },
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
        tpe: 2,
      };
      getCollectDetail(params).then((res) => {
        console.log("res", res);
      });
    },
    submit() {
      console.log(123);
    },
  },
};
</script>
<style lang="less" scoped>
.complaint {
  padding: 0.27rem 0.4rem 0;
  padding-bottom: 1rem;
  position: relative;
  min-height: 14.5rem;
  background: url("../../../assets/images/organization/bg.png");
  background-size: 100% 100%;
  .theme {
    width: 5.5rem;
    margin: 0.3rem auto;
    color: #333333;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.6rem;
  }
  .title {
    margin-bottom: 0.2rem;
    color: #3b93ff;
  }
  .content {
    border-radius: 0.06rem;
    padding: 0.19rem 0.2rem;
    .item-content{
        width: 100%;
        min-height: 2rem;
        border: 1px solid #f6faff;
        border-radius: .1rem;
        padding: .2rem;
    }
    .text-area {
      border-radius: 0.2rem;
      background-color: #f6faff;
    }
  }
  .btn {
    color: #fff;
    background: #3b93ff;
    width: 6.55rem;
    display: block;
    border-radius: 0.2rem;
    line-height: 0.88rem;
    margin: 1rem auto;
    font-size: 0.38rem;
    letter-spacing: 0.1rem;
  }
}
</style>